import { Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { fixNumber } from "utils";
import {
  INITIAL_PAYMENT_METHOD,
  isAdvance,
  typePaymentOnChange,
} from "../../constants";
import { PaymentRow } from "./PaymentRow";
import { styles } from "./styles";
import { CausationContext } from "contexts";
import { PlusCircleIcon } from "assets";

export const Payments = ({
  paymentsSearch = [],
  paymentsSelected = [],
  amountNeto,
  paymentsAdvances,
  handleSetPaymentsAdvances,
  handleSetPayments,
  dueDate,
  columns,
  formControl,
  isCaused,
  isSuggested,
  setValue,
  show,
}) => {
  const [valueAdvance, setValueAdvance] = useState(0);
  const [amountRegister, setAmountRegister] = useState(0);
  const { hasErpCausation } = useContext(CausationContext);

  return (
    <PaymentsContent
      paymentsSearch={paymentsSearch}
      paymentsSelected={paymentsSelected}
      amountNeto={amountNeto}
      paymentsAdvances={paymentsAdvances}
      handleSetPaymentsAdvances={handleSetPaymentsAdvances}
      handleSetPayments={handleSetPayments}
      dueDate={dueDate}
      columns={columns}
      formControl={formControl}
      valueAdvance={valueAdvance}
      setValueAdvance={setValueAdvance}
      amountRegister={amountRegister}
      setAmountRegister={setAmountRegister}
      hasErpCausation={hasErpCausation}
      isCaused={isCaused}
      isSuggested={isSuggested}
      setValue={setValue}
      show={show}
    />
  );
};

const PaymentsContent = ({
  paymentsSearch,
  paymentsSelected,
  amountNeto,
  paymentsAdvances,
  handleSetPaymentsAdvances,
  handleSetPayments,
  dueDate,
  columns,
  formControl,
  valueAdvance,
  setValueAdvance,
  amountRegister,
  setAmountRegister,
  hasErpCausation,
  isCaused,
  isSuggested,
  setValue,
  show,
}) => {
  const amountAvailable =
    fixNumber(amountNeto - amountRegister) > 0
      ? fixNumber(amountNeto - amountRegister)
      : 0;

  const addNewPayment = () => {
    const newPayments = [...paymentsSelected, { ...INITIAL_PAYMENT_METHOD }];
    handleSetPayments(newPayments);
  };

  const formatValuePayment = (value, preValue, amountAvailable, dueDate) => {
    if (value === null) {
      return { ...INITIAL_PAYMENT_METHOD };
    }
    if (value?.accounting_concept === isAdvance) {
      return {
        ...preValue,
        accounting_concept: value?.accounting_concept,
        name: value?.accounting_concept,
        data: {},
      };
    }

    const { amount, accounting_concept, name, ...data } = value;
    return {
      ...preValue,
      accounting_concept: accounting_concept,
      name: name,
      data: { ...data, ...(value?.due_type === -1 && { dueDate: dueDate }) },
      amount: amountAvailable,
    };
  };

  const deselectAdvance = (advancePrevValue, paymentsUpdate, index) => {
    if (advancePrevValue.data?.AcDueBalanceID) {
      const advanceUpdate = paymentsAdvances.map((advance) => {
        if (advance.AcDueBalanceID === advancePrevValue.data.AcDueBalanceID) {
          return {
            ...advance,
            isUsed: false,
          };
        }
        return advance;
      });
      handleSetPaymentsAdvances(advanceUpdate);
    }
    paymentsUpdate[index] = {
      ...paymentsUpdate[index],
      data: {},
      amount: 0,
    };

    handleSetPayments(paymentsUpdate);
  };

  const setSelectedAdvance = (
    value,
    paymentsUpdate,
    amountAvailable,
    index
  ) => {
    const advance = paymentsAdvances.find(
      (advance) => advance.AcDueBalanceID === value
    );

    const advancePrev = { ...paymentsUpdate[index].data };

    if (advance) {
      paymentsUpdate[index] = {
        ...paymentsUpdate[index],
        data: advance,
        amount:
          advance?.Value > amountAvailable ? amountAvailable : advance?.Value,
      };
      advance.isUsed = true;
      const advancesUpdate = paymentsAdvances.filter(
        (advance) => advance.AcDueBalanceID !== value
      );
      let advancePrevUpdate = advancesUpdate;
      if (advancePrev.AcDueBalanceID) {
        advancePrevUpdate = advancesUpdate.map((advance) => {
          if (advance.AcDueBalanceID === advancePrev.AcDueBalanceID) {
            return {
              ...advance,
              isUsed: false,
            };
          }
          return advance;
        });
      }
      handleSetPaymentsAdvances([...advancePrevUpdate, { ...advance }]);
    }
  };

  const handleOnChangePayment = (index, value, typeChange) => {
    const paymentsUpdate = [...paymentsSelected];

    if (typeChange === typePaymentOnChange.payment) {
      paymentsUpdate[index] = formatValuePayment(
        value,
        paymentsUpdate[index],
        amountNeto,
        dueDate
      );
    } else if (typeChange === typePaymentOnChange.advance) {
      if (!value) {
        deselectAdvance(paymentsUpdate[index], paymentsUpdate, index);
        return;
      }
      setSelectedAdvance(value, paymentsUpdate, amountAvailable, index);
    } else if (typeChange === typePaymentOnChange.dueDate) {
      paymentsUpdate[index].data.dueDate = value;
    } else if (typeChange === typePaymentOnChange.amount) {
      paymentsUpdate[index].amount = value;
    } else if (typeChange === typePaymentOnChange.shareNumber) {
      paymentsUpdate[index].share_number = value;
    }

    handleSetPayments(paymentsUpdate);
  };

  const handleDeletePayment = (index) => {
    const payment = paymentsSelected[index];
    const newPayments = paymentsSelected.filter((_, i) => i !== index);

    // Manejar la liberación del anticipo si existe
    if (payment?.data?.AcDueBalanceID) {
      const advanceUpdate = paymentsAdvances.map((advance) => {
        if (advance.AcDueBalanceID === payment.data.AcDueBalanceID) {
          return {
            ...advance,
            isUsed: false,
          };
        }
        return advance;
      });
      handleSetPaymentsAdvances(advanceUpdate);
    }

    // Si no quedan pagos, agregar uno vacío
    if (newPayments.length === 0) {
      handleSetPayments([{ ...INITIAL_PAYMENT_METHOD }]);
    } else {
      handleSetPayments(newPayments);
    }
  };

  const setAdvanceAndAmount = () => {
    const advance = paymentsSearch.find(
      (payment) => payment?.accounting_concept === isAdvance
    );
    if (advance) {
      const value = advance?.purchaseAdvances.reduce(
        (acc, curr) => acc + curr.Value,
        0
      );
      setValueAdvance(value);
      handleSetPaymentsAdvances(
        advance?.purchaseAdvances?.map((advance) => ({
          isUsed: false,
          ...advance,
        }))
      );
    } else {
      setValueAdvance(0);
    }
  };

  const getAmountRegisterToPay = () => {
    const totalRegister = paymentsSelected?.reduce((acc, curr) => {
      return acc + +curr.amount;
    }, 0);
    setAmountRegister(totalRegister);
  };

  useEffect(() => {
    setAdvanceAndAmount();
  }, [paymentsSearch]);

  useEffect(() => {
    getAmountRegisterToPay();
  }, [paymentsSelected]);

  return (
    <Stack
      sx={{
        border: "1px solid rgb(239, 242, 246)",
        borderRadius: "8px",
        padding: "16px",
        height: "100%",
      }}
    >
      <div
        style={{
          padding: "0px",
        }}
        className="multiplePayments"
      >
        <Typography style={styles.title}>Formas de pago</Typography>
        {show && (
          <Typography style={styles.subtitleProviderAdvance}>
            El proveedor tiene anticipos por{" "}
            <NumericFormat
              tabIndex="0"
              value={valueAdvance}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        )}
        <PaymentRow
          paymentsSelected={paymentsSelected}
          handleOnChangePayment={handleOnChangePayment}
          paymentsSearch={paymentsSearch}
          paymentsAdvances={paymentsAdvances}
          handleDeletePayment={handleDeletePayment}
          columns={columns}
          control={formControl}
          isCaused={isCaused}
          isSuggested={isSuggested}
          setValue={setValue}
        />
        {!isCaused && (
          <div
            style={{ ...styles.textAddNewItem, width: "fit-content" }}
            onClick={addNewPayment}
            className="addNewPayment"
          >
            <PlusCircleIcon width="20" height="20" />
            Agregar forma de pago
          </div>
        )}
        {amountRegister !== amountNeto && amountRegister !== 0 && (
          <Typography style={styles.warningText}>
            El total de las formas de pago debe coincidir con el total neto.
          </Typography>
        )}
        {amountRegister !== amountNeto &&
          amountRegister !== 0 &&
          amountAvailable > 0 && (
            <Typography style={styles.warningText}>
              Resta definir:{" "}
              <NumericFormat
                value={amountAvailable}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
          )}
      </div>
    </Stack>
  );
};

const AddNewItem = ({ onClick, text, icon }) => (
  <Typography onClick={onClick} sx={styles.textAddNewItem}>
    {icon} {text}
  </Typography>
);

const WarningText = ({ amountNeto, text, withParenthesis }) => {
  return withParenthesis ? (
    <Typography sx={styles.warningText}>
      {text} (
      <NumericFormat
        tabIndex="0"
        value={amountNeto}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
      )
    </Typography>
  ) : (
    <Typography sx={styles.warningText}>
      {text}{" "}
      <NumericFormat
        tabIndex="0"
        value={amountNeto}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={"$"}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    </Typography>
  );
};
