import { AutocompleteTable, Tooltip } from "commons/components";
import { CheckboxPayana } from "commons/components/CheckboxPayana.js";
import {
  InputDescription,
  InputEditNumberRow,
  InputNumberRow,
  Suggestion,
} from "../components";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import { DeleteIcon } from "assets";
import { Controller } from "react-hook-form";
import React from "react";

export const tableColumns = ({
  searchProductsOptions,
  taxIva,
  taxesRete,
  handleOnChangeTableItem = () => {},
  handleDeleteItem = () => {},
  getSearchOptions = () => {},
  isCaused,
  selectItem = () => {},
  idCellRef = () => {},
  handleSelectAllItems = () => {},
  control,
  errors,
  formValues,
  register,
  costCenter,
  getSearchOptionsSupplier = () => {},
  setValue,
}) => {
  const getItemIndex = (id) =>
    formValues.items.findIndex((item) => item.id === id);

  const columns = [
    {
      field: "product",
      headerName: "Producto",
      sortable: false,
      flex: 1,
      cellClassName: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        const error = errors?.items?.[index]?.product;
        const isSuggested = params.row.suggestedFields?.product;
        return `super-app-theme--cell ${
          params.row.isSelected ? "cell-select" : ""
        } ${error ? "error-cell" : ""} ${
          isSuggested && !params.row.isSelected ? "suggested-cell" : ""
        }`;
      },
      renderCell: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              backgroundColor: "transparent",
              border: "none",
              marginLeft: params.row.isSelected ? "1px" : "2px",
            }}
            className="multipleSelect"
            ref={idCellRef}
          >
            {!isCaused && (
              <CheckboxPayana
                onClick={() => selectItem(index)}
                checked={params.row.isSelected}
              />
            )}
            <AutocompleteTable
              required={true}
              register={register}
              name={`items.${index}.product`}
              value={params.row.product || null}
              disabled={isCaused}
              options={searchProductsOptions}
              tabIndex={params.tabIndex}
              error={errors?.items?.[index]?.product?.message}
              getOptionLabel={(option) =>
                option?.fname
                  ? `${option?.fcode?.trim()} - ${option?.fname?.trim()}`
                  : ""
              }
              onChange={(event, option) => {
                const items = [...formValues.items];
                if (items[index]?.suggestedFields) {
                  items[index].suggestedFields.product = false;
                }
                handleOnChangeTableItem(params, "product", option);
              }}
              isSuggested={params.row.suggestedFields?.product}
              onInputChange={(event, newValue) => {
                if (
                  event &&
                  (event.type === "change" ||
                    (event.type === "click" && !newValue))
                ) {
                  getSearchOptions("product", newValue);
                }
              }}
              tableConfig={{
                columns: [
                  {
                    headerName: "Código",
                    getText: (option) => option?.fcode,
                  },
                  {
                    headerName: "Descripción",
                    getText: (option) => option?.fname?.trim(),
                  },
                ],
                rowClassName: "causation",
                getRowTooltipText: (option) =>
                  `${option?.fcode} - ${option?.fname}`,
              }}
            />
          </div>
        );
      },
      renderHeader: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              padding: "1px",
            }}
          >
            {!isCaused && <CheckboxPayana onClick={handleSelectAllItems} />}
            <p
              style={{
                fontSize: "12px",
                color: "#101828",
                fontWeight: 600,
                lineHeight: "18px",
                marginBottom: "0px",
              }}
            >
              Producto
            </p>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Descripción",
      sortable: false,
      flex: 1,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Descripción
          </p>
        );
      },
      renderCell: (params) => {
        const index = getItemIndex(params.row.id);
        const isSuggested = params.row.suggestedFields?.description;

        return (
          <InputDescription
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) => {
              const items = [...formValues.items];
              if (items[index]?.suggestedFields) {
                items[index].suggestedFields.description = false;
              }
              handleOnChangeTableItem(params, "description", value);
            }}
            style={{
              ...(isSuggested && {
                backgroundColor: "#FAFAFF",
                borderColor: "#BDB4FE",
              }),
            }}
          />
        );
      },
    },
    {
      field: "itemCostCenter",
      headerName: "itemCostCenter",
      width: 163,
      sortable: false,
      cellClassName: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        const error = errors?.items?.[index]?.costCenter;
        const isSuggested = params.row.suggestedFields?.costCenter;
        return `super-app-theme--cell ${
          params.row.isSelected ? "cell-select" : ""
        } ${error ? "error-cell" : ""} ${isSuggested ? "suggested-cell" : ""}`;
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Centro de costos
          </p>
        );
      },
      renderCell: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        const isSuggested = params.row.suggestedFields?.costCenter;

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Autocomplete
              name="itemCostCenter"
              size="small"
              options={costCenter}
              required={true}
              freeSolo
              getOptionLabel={(option) => {
                return option?.code
                  ? `${option.code} - ${option?.name}`
                  : option?.name;
              }}
              onChange={(event, value) => {
                handleOnChangeTableItem(params, "costCenter", value);
              }}
              onInputChange={(event) => {
                getSearchOptionsSupplier("costCenter", event?.target?.value);
              }}
              filterOptions={(options, { inputValue }) => {
                const inputValueLower = inputValue.toLowerCase();
                return options?.filter(
                  (option) =>
                    option?.name?.toLowerCase().includes(inputValueLower) ||
                    option?.code?.toString().includes(inputValueLower)
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option?.id} style={{ fontSize: "14px" }}>
                    {option?.code
                      ? `${option.code} - ${option?.name}`
                      : option?.name}
                  </li>
                );
              }}
              PaperComponent={({ children }) => (
                <Box className="autocomplete-container">{children}</Box>
              )}
              sx={{
                ...stylesSelectedTaxs,
                flex: 1,
                "& .MuiAutocomplete-input": {
                  fontSize: "14px",
                  padding: "2px 4px !important",
                  color: isSuggested ? "#6938EF" : "inherit",
                  fontWeight: isSuggested ? "500" : "inherit",
                },
                "& .MuiInputBase-root": {
                  height: "100%",
                  padding: "2px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: "left",
                  border: "none",
                  borderRadius: "0",
                },
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "0px !important",
                },
                "& .MuiAutocomplete-endAdornment": {
                  display: "none",
                },
                "& .MuiInputBase-input": {
                  height: "100% !important",
                  display: "flex !important",
                  alignItems: "center !important",
                  justifyContent: "flex-start !important",
                  padding: "0 !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiDataGrid-root .MuiDataGrid-cell": {
                  padding: "0px !important",
                },
              }}
              value={
                costCenter.find(
                  (item) => item.id === params.row.costCenter?.id
                ) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: 14 },
                    ...(isSuggested && {
                      startAdornment: (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mr: 1,
                            backgroundColor: "transparent",
                          }}
                        >
                          <Suggestion />
                        </Box>
                      ),
                    }),
                  }}
                />
              )}
            />
          </Box>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Cant.",
      type: "number",
      editable: !isCaused,
      width: 75,
      sortable: false,
      align: "left",
      headerAlign: "left",
      cellClassName: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        const error = errors?.items?.[index]?.quantity;
        const isSuggested = params.row.suggestedFields?.quantity;
        return `super-app-theme--cell ${error ? "error-cell" : ""} ${
          isSuggested ? "suggested-cell" : ""
        }`;
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
              width: "100%",
              textAlign: "right",
              paddingRight: "14px",
            }}
          >
            Cant.
          </p>
        );
      },
      renderEditCell: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        return (
          <InputEditNumberRow
            error={errors?.items?.[index]?.quantity?.message}
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(newValue) => {
              const items = [...formValues.items];
              if (items[index]?.suggestedFields) {
                items[index].suggestedFields.quantity = false;
              }
              handleOnChangeTableItem(params, "quantity", newValue);
            }}
            formatMoney={false}
            {...params}
            style={{
              ...(params.row.suggestedFields?.quantity && {
                backgroundColor: "#FAFAFF",
                borderColor: "#BDB4FE",
              }),
            }}
          />
        );
      },
      renderCell: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        return (
          <Controller
            control={control}
            name={`items.${index}.quantity`}
            defaultValue=""
            rules={{
              required: "Este campo es requerido",
              validate: {
                positive: (value) => {
                  const numValue = Number(value);
                  return numValue <= 0
                    ? "La cantidad debe ser mayor a 0"
                    : true;
                },
                isNumber: (value) => {
                  const numValue = Number(value);
                  return isNaN(numValue) ? "Debe ser un número válido" : true;
                },
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Tooltip title={errors?.items?.[index]?.quantity?.message}>
                <div
                  style={{
                    paddingRight: "4px",
                    ...(params.row.suggestedFields?.quantity && {
                      backgroundColor: "#FAFAFF",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      border: "1px solid #BDB4FE",
                    }),
                  }}
                >
                  {value}
                </div>
              </Tooltip>
            )}
          />
        );
      },
    },
    {
      field: "unitValue",
      headerName: "Valor unitario",
      type: "number",
      editable: !isCaused,
      sortable: false,
      width: 130,
      cellClassName: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        const error = errors?.items?.[index]?.unitValue;
        const isSuggested = params.row.suggestedFields?.unitValue;
        return `super-app-theme--cell ${error ? "error-cell" : ""} ${
          isSuggested ? "suggested-cell" : ""
        }`;
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Valor unitario
          </p>
        );
      },
      renderCell: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        return (
          <Controller
            control={control}
            name={`items.${index}.unitValue`}
            defaultValue=""
            rules={{
              required: "Este campo es requerido",
              validate: {
                positive: (value) => {
                  const numValue = Number(value);
                  return numValue < 0
                    ? "El valor unitario debe ser mayor a $0"
                    : true;
                },
                isNumber: (value) => {
                  const numValue = Number(value);
                  return isNaN(numValue) ? "Debe ser un número válido" : true;
                },
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <InputNumberRow
                value={value}
                errorMessage={errors?.items?.[index]?.unitValue?.message}
              />
            )}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) =>
              handleOnChangeTableItem(params, "unitValue", value)
            }
            formatMoney={true}
            {...params}
          />
        );
      },
    },
    {
      field: "discount",
      headerName: "Descuento",
      type: "number",
      editable: !isCaused,
      sortable: false,
      width: 100,
      cellClassName: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        const error = errors?.items?.[index]?.discount;
        const isSuggested = params.row.suggestedFields?.discount;
        return `super-app-theme--cell ${error ? "error-cell" : ""} ${
          isSuggested ? "suggested-cell" : ""
        }`;
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Descuento
          </p>
        );
      },
      renderCell: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        return (
          <Controller
            control={control}
            name={`items.${index}.discount`}
            defaultValue=""
            rules={{
              required: "Este campo es requerido",
              validate: {
                positive: (value) => {
                  const numValue = Number(value);
                  return numValue < 0
                    ? "El descuento debe ser mayor a $0"
                    : true;
                },
                isNumber: (value) => {
                  const numValue = Number(value);
                  return isNaN(numValue) ? "Debe ser un número válido" : true;
                },
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <InputNumberRow
                value={value}
                errorMessage={errors?.items?.[index]?.discount?.message}
              />
            )}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <InputEditNumberRow
            isCaused={isCaused}
            valueRow={params.value}
            onChange={(value) =>
              handleOnChangeTableItem(params, "discount", value)
            }
            formatMoney={true}
            {...params}
          />
        );
      },
    },
    {
      field: "taxIva",
      headerName: "Imp. Cargo",
      sortable: false,
      width: 170,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Imp. Cargo
          </p>
        );
      },
      cellClassName: (params) => {
        const index = getItemIndex(params.row.id);
        const error = errors?.items?.[index]?.taxIva;
        const isSuggested = params.row.suggestedFields?.taxIva;
        return `super-app-theme--cell ${
          params.row.isSelected ? "cell-select" : ""
        } ${error ? "error-cell" : ""} ${
          isSuggested && !params.row.isSelected ? "suggested-cell" : ""
        }`;
      },
      renderCell: (params) => {
        const isSuggested = params.row.suggestedFields?.taxIva;

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#F2F4F7",
                minWidth: "40px",
                height: "100%",
                color: "#667085",
                textAlign: "center",
                marginLeft: "-10px",
                marginRight: "4px",
              }}
            >
              <p style={{ margin: 0 }}>{`${
                params?.row?.taxIvaPercentage >= 0
                  ? `${params?.row?.taxIvaPercentage}%`
                  : ""
              }`}</p>
            </div>
            <Tooltip title={params?.value?.description || ""}>
              <Autocomplete
                disabled={isCaused}
                PaperComponent={({ children }) => (
                  <Box className="autocomplete-container">{children}</Box>
                )}
                options={taxIva}
                getOptionLabel={(option) => option?.description || ""}
                value={
                  taxIva.find((tax) => tax?.id === params?.value?.id) || null
                }
                onChange={(_, newValue) => {
                  handleOnChangeTableItem(
                    params,
                    "taxIva",
                    newValue?.id || null
                  );
                }}
                size="small"
                sx={{
                  ...stylesSelectedTaxs,
                  flex: 1,
                  "& .MuiAutocomplete-input": {
                    fontSize: "14px",
                    padding: "2px 4px !important",
                    color: isSuggested ? "#6938EF" : "inherit",
                    fontWeight: isSuggested ? "500" : "inherit",
                  },
                  "& .MuiInputBase-root": {
                    height: "100%",
                    padding: "2px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "left",
                    border: "none",
                    borderRadius: "0",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: "0px !important",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    display: "none",
                  },
                  "& .MuiInputBase-input": {
                    height: "100% !important",
                    display: "flex !important",
                    alignItems: "center !important",
                    justifyContent: "flex-start !important",
                    padding: "0 !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiDataGrid-root .MuiDataGrid-cell": {
                    padding: "0px !important",
                  },
                }}
                noOptionsText="No se encontró ningún resultado para tu búsqueda"
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option?.id}
                      style={{
                        fontSize: "14px",
                        padding: "8px 12px",
                      }}
                    >
                      {option?.description}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: 14,
                        padding: "0px !important",
                        flexDirection: "row",
                      },
                      ...(isSuggested && {
                        startAdornment: (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 1,
                              backgroundColor: "transparent",
                            }}
                          >
                            <Suggestion />
                          </Box>
                        ),
                      }),
                    }}
                  />
                )}
              />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "taxRetefuente",
      headerName: "Imp. Retención",
      sortable: false,
      width: 155,
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Imp. Retención
          </p>
        );
      },
      cellClassName: (params) => {
        const index = getItemIndex(params.row.id);
        const error = errors?.items?.[index]?.taxRetefuente;
        const isSuggested = params.row.suggestedFields?.taxRetefuente;
        return `super-app-theme--cell ${
          params.row.isSelected ? "cell-select" : ""
        } ${error ? "error-cell" : ""} ${
          isSuggested && !params.row.isSelected ? "suggested-cell" : ""
        }`;
      },
      renderCell: (params) => {
        const isSuggested = params.row.suggestedFields?.taxRetefuente;

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Tooltip title={params?.value?.description || ""}>
              <Autocomplete
                disabled={isCaused}
                PaperComponent={({ children }) => (
                  <Box className="autocomplete-container">{children}</Box>
                )}
                options={taxesRete}
                getOptionLabel={(option) => option.description || ""}
                value={
                  taxesRete.find((tax) => tax.id === params.value.id) || null
                }
                onChange={(_, newValue) => {
                  handleOnChangeTableItem(
                    params,
                    "taxRetefuente",
                    newValue?.id || null
                  );
                }}
                size="small"
                sx={{
                  ...stylesSelectedTaxs,
                  flex: 1,
                  "& .MuiAutocomplete-input": {
                    fontSize: "14px",
                    padding: "2px 4px !important",
                    color: isSuggested ? "#6938EF" : "inherit",
                    fontWeight: isSuggested ? "500" : "inherit",
                  },
                  "& .MuiInputBase-root": {
                    height: "100%",
                    padding: "2px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "left",
                    border: "none",
                    borderRadius: "0",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    display: "none",
                  },
                  "& .MuiInputBase-input": {
                    height: "100% !important",
                    display: "flex !important",
                    alignItems: "center !important",
                    justifyContent: "flex-start !important",
                    padding: "0 !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                noOptionsText="No se encontró ningún resultado para tu búsqueda"
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option?.id}
                      style={{
                        fontSize: "14px",
                        padding: "8px 12px",
                      }}
                    >
                      {option.description}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: 14,
                        padding: "0px",
                        textAlign: "center",
                      },
                      ...(isSuggested && {
                        startAdornment: (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 1,
                              backgroundColor: "transparent",
                            }}
                          >
                            <Suggestion />
                          </Box>
                        ),
                      }),
                    }}
                  />
                )}
              />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "amountTotal",
      headerName: "Valor Total",
      sortable: false,
      width: 110,
      cellClassName: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        const error = errors?.items?.[index]?.amountTotal;
        const isSuggested = params.row.suggestedFields?.amountTotal;
        return `super-app-theme--cell ${error ? "error-cell" : ""} ${
          isSuggested ? "suggested-cell" : ""
        }`;
      },
      renderHeader: (params) => {
        return (
          <p
            style={{
              fontSize: "12px",
              color: "#101828",
              fontWeight: 600,
              lineHeight: "18px",
              marginBottom: "0px",
            }}
          >
            Valor Total
          </p>
        );
      },
      type: "number",
      renderCell: (params) => {
        const index = formValues.items.findIndex(
          (item) => item.id === params.row.id
        );
        return (
          <Controller
            control={control}
            name={`items.${index}.amountTotal`}
            defaultValue=""
            rules={{
              required: "Este campo es requerido",
              validate: {
                positive: (value) => {
                  const numValue = Number(value);
                  return numValue < 0
                    ? "El descuento debe ser mayor a $0"
                    : true;
                },
                isNumber: (value) => {
                  const numValue = Number(value);
                  return isNaN(numValue) ? "Debe ser un número válido" : true;
                },
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <InputNumberRow
                value={params.value}
                errorMessage={errors?.items?.[index]?.amountTotal?.message}
              />
            )}
          />
        );
      },
    },
  ];

  if (!isCaused) {
    const deleteItemColumn = {
      field: "deleteItem",
      headerName: "",
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteItem(params.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    };
    columns.push(deleteItemColumn);
  }

  return columns;
};

const stylesSelectedTaxs = {
  fontSize: "13px",
  width: "100%",
  "& fieldset": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiSelect-select": {
    padding: "0px !important",
    width: "100%",
    height: "100%",
    maxHeight: "50px",
    display: "flex",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  "& .MuiAutocomplete-input": {
    fontSize: "14px",
  },
  "& .MuiAutocomplete-option": {
    fontSize: "14px",
  },
};
